<template>
  <div :class="$style.add">
    <p>请选择对应的媒体平台授权</p>
    <div v-for="item in data" :key="item.id" :class="$style.box" @click="onDetail(item)">
      <img :class="$style.img" :src="item.icon" />
      <p :class="$style.name">{{ item.name }}</p>
    </div>

    <bj-modal :class="$style.modal" title="微信公众号授权" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div :class="$style.tip">
        当前系统中已经存在你授权过的公众号，如果需要将此公众号添加为文化传播公众号，请选择对应公众号即可，否则请授权新的公众号。
      </div>
      <p :class="$style.title">请选择系统已授权微信公众号</p>
      <a-row :gutter="20">
        <a-col v-for="(item, index) in wx.data" :key="index" span="12">
          <div :class="$style.item">
            <img :src="item.avatar" />
            <img :src="item.platform_icon" />
          </div>
        </a-col>
      </a-row>
      <p>或，授权新的微信公众号</p>
      <div @click="onNew()">
        <i class="ri-add-line" />
        微信公众号授权
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { spreadManageService } from '@/service'

const service = new spreadManageService()

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      visible: false,
      wx: {
        data: [],
      },
      active: null,
    }
  },
  created() {
    this.getInfo()
    // this.getWx()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getType()
        this.data = data
      } catch (e) {}
    },
    onDetail(item) {
      if (item.platform_type === 'wx') {
        // this.visible = true
        this.$router.push({
          name: 'spreadManageAddWx',
        })
      }
    },
    // async getWx() {
    //   const { data } = await service.getWxList()
    //   this.wx.data = data
    //   this.active = data[0].id
    // },
    onNew() {
      this.$router.push({
        name: 'spreadManageAddWx',
      })
    },
    async handleOk() {
      try {
        await service.selectOfficial({
          id: this.active,
        })
        this.visible = false
        this.$router.push({
          name: 'spreadManage',
        })
      } catch (e) {}
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.add {
  .box {
    width: 130px;
    height: 130px;
    background: #fff;
    border: 1px solid #eee;
    text-align: center;
    cursor: pointer;

    .img {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      margin-top: 26px;
      margin-bottom: 14px;
    }

    .name {
      font-size: 12px;
    }
  }
}

.modal {
  .tip {
    background: #fafafa;
    color: #5c5c5c;
    padding: 10px;
    font-size: 12px;
  }

  .title {
    font-size: 14px;
    color: #000;
  }

  .item {
    width: 235px;
    height: 78px;
    border: 1px solid #eee;
    display: flex;
  }
}
</style>
